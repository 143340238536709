import React from 'react'

// Use a global to save the user, so we don't have to fetch it again after page navigations
let userState

const User = React.createContext({
  user: null,
  currentTeam: null,
  switchTeam: () => {},
})

const redirectToLogin = () => {
  window.location.href = '/api/auth/login'
  return
}

export const fetchUser = async () => {
  if (userState !== undefined) {
    return userState
  }

  const res = await fetch('/api/auth/profile')
  userState = res.ok ? await res.json() : null

  return userState
}

export const UserProvider = ({ value, children }) => {
  const { user } = value

  // If the user was fetched in SSR add it to userState so we don't fetch it again
  React.useEffect(() => {
    if (!userState && user) {
      userState = user
    }
  }, [user])

  return <User.Provider value={value}>{children}</User.Provider>
}

export const useUser = () => {
  const { user } = React.useContext(User)
  return user
}

export const useTeam = () => {
  const { team } = React.useContext(User)
  return team
}

export const useTeamSwitcher = () => {
  const { switchTeam } = React.useContext(User)
  return switchTeam
}

export const useFetchUser = ({ required } = { required: true }) => {
  const [data, setData] = React.useState(userState || null)

  React.useEffect(() => {
    if (userState !== undefined) {
      return
    }

    let isMounted = true

    fetchUser().then((user) => {
      // Only set the user if the component is still mounted
      if (isMounted) {
        setData(user)

        if (!user && required) {
          redirectToLogin()
        }
      }
    })

    return () => {
      isMounted = false
    }
  }, [required])

  return data
}
