import React, { useState, useEffect, useCallback } from 'react'
import { useFetchUser, UserProvider } from 'lib/user'
import Transition from 'components/Transition'
import Loader from 'components/Loader'
import { useRouter } from 'next/router'
import { useCookies } from 'react-cookie'

const withAuth = (Component) => (props) => {
  const router = useRouter()
  const user = useFetchUser()

  const [cookies, setCookie] = useCookies(['currentTeamId'])
  const [team, setTeam] = useState(() => {
    if (user) {
      return user.teams.find((team) => team.id === cookies['currentTeamId'])
    }
  })

  const setCurrentTeamIdCookie = useCallback(
    (id) => {
      setCookie('currentTeamId', id, {
        path: '/',
        secure: process.env.NODE_ENV === 'production',
        sameSite: 'lax',
      })
    },
    [setCookie]
  )

  useEffect(() => {
    if (user && router.query.slug) {
      const currentTeamId = cookies['currentTeamId']
      const currentTeam = user.teams.find(
        (team) => team.slug === router.query.slug
      )

      if (currentTeam) {
        if (currentTeam.id !== currentTeamId) {
          setCurrentTeamIdCookie(currentTeam.id)
          setTeam(currentTeam)
        }
      } else {
        router.replace('/app/[slug]', `/app/${user.teams[0].slug}`)
      }
    }
  }, [user, router, cookies, setCurrentTeamIdCookie])

  useEffect(() => {
    if (user) {
      const currentTeamId = cookies['currentTeamId']

      if (currentTeamId) {
        setTeam(user.teams.find((team) => team.id === currentTeamId))
      } else {
        const defaultTeam = user.teams[0]

        setTeam(defaultTeam)
        setCurrentTeamIdCookie(defaultTeam.id)
      }
    }
  }, [cookies, setCurrentTeamIdCookie, user])

  const switchTeam = (newTeamId) => {
    if (team.id === newTeamId) {
      return
    }

    const newTeam = user.teams.find((team) => team.id === newTeamId)

    if (newTeam) {
      setTeam(newTeam)
      setCurrentTeamIdCookie(newTeam.id)
      router.push('/app/[slug]', `/app/${newTeam.slug}`)
    }
  }

  const loading = !user || !team

  return (
    <>
      <Transition
        show={loading}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0"
        enterTo="transform opacity-100"
        leave="ransition ease-out duration-500"
        leaveFrom="transform opacity-100"
        leaveTo="transform opacity-0"
      >
        <div className="absolute inset-0 h-screen w-full bg-gray-white flex items-center justify-center z-50">
          <div className="flex items-center text-gray-500">
            <Loader className="w-4 h-4 mr-2" /> Loading
          </div>
        </div>
      </Transition>

      <Transition
        show={!loading}
        enter="transition ease-out duration-500"
        enterFrom="transform opacity-0"
        enterTo="transform opacity-100"
        leave="ransition ease-out duration-500"
        leaveFrom="transform opacity-100"
        leaveTo="transform opacity-0"
      >
        <UserProvider value={{ user, team, switchTeam }}>
          <Component {...props} user={user} />
        </UserProvider>
      </Transition>
    </>
  )
}

export default withAuth
