import React from 'react'
import { useTeam } from 'lib/user'
import { useRouter } from 'next/router'
import withAuth from 'components/withAuth'

export const Index = () => {
  const team = useTeam()
  const router = useRouter()

  router.replace('/app/[slug]', `/app/${team.slug}`)

  return <div />
}

export default withAuth(Index)
